import { Page } from '~/cms/front/core/pages/Page'
import component from './DownloadPage.vue'
import path from 'path'

class DownloadPage extends Page {
	static alias = 'download'

	async init () {
		await super.init(...arguments)
		await this.app.translator.prefetch('download')
	}

	async download (item) {
		const service = this.app.getService('rext')
		const bufferData = await service.getFile(item.file.path)

		const fileUrl = window.URL.createObjectURL(new Blob([bufferData]))
		const fileLink = document.createElement('a')
		const fileName = path.basename(decodeURI(item.file.path))

		fileLink.href = fileUrl
		fileLink.setAttribute('download', fileName)
		document.body.appendChild(fileLink)

		fileLink.click()
	}

	component = component
}

export { DownloadPage }
