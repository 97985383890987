<template>
	<div class="download">
        <base-font variant="paragraph" weight="bold" uppercase class="download-paragraph">{{item.name}}</base-font>
        <div class="download-files">
            <base-font variant="fileType" class="download-file-show">.{{fileType}}</base-font>
            <base-link @click="download(item)" class="download-file" underline :title="`Pobierz plik - ${item.name}`">
                <base-svg name="download"/>
                <base-font color="secondary">{{translate.download}}</base-font>
            </base-link>
        </div>
    </div>
</template>
<script>
import path from 'path'
export default {
	props: {
		item: Object
	},
	computed: {
		translate () {
			return this.$app.translator.get('download')
		},
		fileType () {
			try {
				return this.item.file.path.slice((this.item.file.path.lastIndexOf('.') - 1 >>> 0) + 2)
			} catch (e) {
				console.error(e)
				return ''
			}
		},
		page () {
			return this.$app.page
		}
	},
	methods: {
		async download (item) {
			const service = this.$app.getService('rext')
			const bufferData = await service.getFile(item.file.path)

			const fileUrl = window.URL.createObjectURL(new Blob([bufferData]))
			const fileLink = document.createElement('a')
			const fileName = path.basename(decodeURI(item.file.path))

			fileLink.href = fileUrl
			fileLink.setAttribute('download', fileName)
			document.body.appendChild(fileLink)

			fileLink.click()
		}
	}
}
</script>
<style lang="scss" scoped>
    .download {
            position: relative;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 2rem;
            min-height: 17rem;
            margin-bottom: 2rem;
            min-width: 100%;
            border: 1px solid $gray-300;
            @include media-breakpoint-up(md) {
                margin-bottom: 0;
            }
            &::before {
                content: '';
                position: absolute;
                top:0;
                left:0;
                width: 100%;
                height: 4px;
                background-color: $secondary;
            }
        &-files {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        .download-file {
            display: flex;
            align-items: center;
            @include media-breakpoint-up(xl) {
                &:hover {
                    ::v-deep .svg-icon {
                        fill: $secondary-300;
                    }
                }
            }
            .svg-icon {
                width: 15px;
                height: 15px;
                margin-right: .5rem;
            }
        }
        ::v-deep .download-file-show {
            padding: 1rem;
            border-radius: .5rem;
            background: $secondary;
        }
    }
</style>
