<template>
	<div class="category-wrapper" v-background="backgroundOverlay">
		<base-container>
			<div class="category">
				<div class="category-list" v-for="(key, value) in categoryData" :key="value">
					<base-font variant="small-heading" color="white">{{lang[value]}}:</base-font>
					<ul class="category-items">
						<base-font tag="li" size="xs" class="category-item" v-for="(categoryElement, index) in key" :key="index">
							<button class="category-button" @click="handleInput(value, categoryElement, $event)">
								<base-svg :class="categoryElement" :name="chooseIcon(categoryElement)"/>
								<base-font tag="span" class="content-wrapper">{{ lang[categoryElement] || categoryElement }} </base-font>
							</button>
						</base-font>
					</ul>
				</div>
			</div>
		</base-container>
	</div>
</template>

<script>
export default {
	props: {
		categoryData: [Array, Object]
	},
	data: () => ({
		selectedCategory: null,
		categoryButtons: []
	}),
	computed: {
		lang () {
			return this.$app.translator.get('download')
		},
		backgroundOverlay () {
			const path = '/homepage/category-image.jpg'
			return {
				src: path,
				breakpoints: {
					base: { width: 720, height: 650 },
					md: { width: 900, height: 650 },
					lg: { width: 1024, height: 451 },
					xl: { width: 1440, height: 800 },
					xxl: { width: 1920, height: 515 }
				}
			}
		}
	},
	methods: {
		handleInput (tag, element, event) {
			if (this.selectedCategory !== element) this.selectedCategory = element
			else this.selectedCategory = null
			this.isActive = !this.isActive
			this.$emit('categorySelected', this.selectedCategory)
			this.setActive(event)
		},
		removeAllActive (current) {
			document.querySelectorAll('.category-button').forEach(item => {
				if (item === current) {
					return false
				} else {
					item.classList.remove('active')
				}
			})
		},
		setActive (event) {
			this.removeAllActive(event.target)
			event.target.classList.toggle('active')
		},
		chooseIcon (category) {
			return {
				Instrukcje: 'info',
				Regulaminy: 'regulamins',
				Inne: 'label'
			}[category]
		}
	},
	async prefetch () {
		await this.$app.translator.prefetch('download')
	}
}
</script>

<style lang="scss" scoped>
.category-wrapper {
	padding: 2.5rem 0;
	//background: $secondary;
	position: relative;
	width: 100%;
	display: flex;
    &::before {
        content: '';
        position: absolute;
        top:0;
        left: 0;
        width:100%;
        height: 100%;
        background: $gray-600;
        opacity: .7;
    }
}
.category {
	display: flex;
	flex-direction: column;
	gap: 20px;
	@include media-breakpoint-up(xl) {
		flex-direction: row;
		gap: 40px;
	}
    &-list {
        z-index: 3;
    }
}
.category-items {
	display: flex;
	gap: 10px;
	margin: 0;
	padding: 0;
	list-style-type: none;
	flex-direction: column;
	@include media-breakpoint-up(md) {
		flex-direction: row;
	}

}

.category-button {
        font-size: 1.6rem;
        padding: 1rem 2.5rem;
        font-weight: normal;
		border-radius: 2px;
		background: $white;
		outline: none;
		text-transform: uppercase;
		border: none;
		cursor: pointer;
		display: flex;
		align-items: center;
		gap: 10px;
		width: 100%;
		justify-content: center;
        position: relative;
        transition: 0.3s ease-in;
        @include media-breakpoint-up(xl) {
            padding: 1.5rem 2rem;
			width: auto;
            height: 100%;
        }
        @include media-breakpoint-up(xxl) {
            padding: 1.5rem 4rem;
        }
		.svg-icon {
			width: 15px;
			height: 15px;
			pointer-events: none;

			&.standard ::v-deep path {
				fill: $gray-400;
			}
			&.exclusive ::v-deep path {
				fill: $yellow-500;
			}
            &.premium ::v-deep path {
                fill: $secondary-300;
            }
			&.Regulaminy ::v-deep path {
				fill: $secondary-300;
			}
			&.Inne ::v-deep path {
				fill: $yellow-500;
			}
		}
        &.active {
            color: $white;
            ::v-deep .content-wrapper {
                color: $white;
                transform: translateY(-3px);
            }
            background: $secondary;
            box-shadow: 0 3px 20px rgba($black, .2);

            .svg-icon {
                transform: translateY(-3px);
               ::v-deep path {
                    fill: $white;
                }
            }
            &::after {
                transform: scaleY(1.8);
            }
        }
        &::after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 4px;
            transition: 0.3s ease-in;
            transform-origin: bottom;
            background: $secondary;
        }
    &:hover {
        ::v-deep .content-wrapper {
            transform: translateY(-3px)
        }
        &::after {
            transform: scaleY(1.8);
        }
        .svg-icon {
            transform: translateY(-3px);
        }
    }
    ::v-deep .content-wrapper {
        transition: 0.3s ease-in;
        letter-spacing: normal;
		pointer-events: none;
    }
    }
</style>
