<template>
	<div class="section-wrapper">
        <base-heading tag="h1" heading="tab-download.main_title" subheading="tab-download.main_subtitle"/>
        <files-categories :categoryData="categories" @categorySelected="selectedCategory = $event"/>
		<div class="category-wrapper" v-for="(categoryItem, firstIndex) in categoryData.category" :key="firstIndex">
			<div class="category-elements-wrapper" v-for="(item, secondIndex) in categoryItem.category_items" :key="secondIndex" v-animate="`opacity`">
                <grid-template cols="4" v-if="shouldCategoryRowBeShown(item)">
                    <template #title>
                        <cms-text class="category-name" :props="{variant:'small-heading', color:'primary'}" :value="`tab-category.category.${firstIndex}.category_items.${secondIndex}.category_item`"/>
                    </template>
                    <download-card v-for="file in getFilesByCategory(item.category_item)" :key="file.name" :item="file"/>
                </grid-template>
			</div>
		</div>
	</div>
</template>
<script>
import DownloadCard from '~/website/front/components/molecules/cards/DownloadCard/DownloadCard.vue'
import GridTemplate from '~/website/front/templates/GridTemplate.vue'
import BaseHeading from '~/website/front/components/molecules/headings/BaseHeading/BaseHeading.vue'
import FilesCategories from '~/website/front/core/pages/DownloadPage/FilesCategories'
import { pageMixin } from '~/website/front/core/pages/page.mixin'

export default {
	components: {
		BaseHeading,
		DownloadCard,
		GridTemplate,
		FilesCategories
	},
	mixins: [pageMixin],
	computed: {
		translate () {
			return this.$app.translator.get('download')
		},
		data () {
			return this.$app.page.value['tab-download']
		},
		categoryData () {
			return this.$app.page.value['tab-category']
		},
		categories () {
			return {
				categories: this.categoryData.category[0].category_items.map(item => item.category_item)
			}
		}
	},
	data: () => ({
		selectedCategory: null
	}),
	methods: {
		getFilesByCategory (category) {
			return this.data.files.filter(file => file.category === category) || []
		},
		shouldCategoryRowBeShown (category) {
			return category.category_item === this.selectedCategory || this.selectedCategory === null
		}
	}
}
</script>
<style lang="scss" scoped>
.section-wrapper {
	position: relative;
    padding: 10rem 0 4rem 0;
	@include media-breakpoint-up(xl) {
		padding: 16rem 0 4rem 0;
	}
    background-color: $white;
}
.category-name {
	margin: 2rem 0;
}
</style>
